import apiClient from "@/services/api";

export default {
  // async downloadTimeAttendance(
  //   { commit, rootState },
  //   { start_date, end_date, report_type = "timesheet" }
  // ) {
  //   const { currentProject } = rootState.applicationState;

  //   try {
  //     const { data } = await apiClient.post(
  //       `/api/project/report/time_attendance/get`,
  //       {
  //         project_id: currentProject.id,
  //         start_date,
  //         end_date,
  //         report_type,
  //         device_id: "9afbdd2824fc7073",
  //       }
  //     );

  //     if (data.error_code && data.error_code !== "0") {
  //       throw new Error("Please try again later");
  //     }
  //   } catch (error) {
  //     throw error.message;
  //   }
  // },

  // async sendTimeAttendance(
  //   { commit, rootState },
  //   { email, start_date, end_date, report_type = "timesheet" }
  // ) {
  //   const { currentProject } = rootState.applicationState;

  //   try {
  //     const { data } = await apiClient.post(
  //       `/api/project/report/time_attendance/send_email`,
  //       {
  //         project_id: currentProject.id,
  //         email,
  //         start_date,
  //         end_date,
  //         report_type,
  //       }
  //     );

  //     if (data.error_code && data.error_code !== "0") {
  //       throw new Error("Please try again later");
  //     }
  //   } catch (error) {
  //     throw error.message;
  //   }
  // },

  async downloadShiftCards({ rootState }, { filters }) {
    const { currentProject, currentPaymentTypeName } =
      rootState.applicationState;

    try {
      const { data } = await apiClient.get(
        `/api/report/project/${currentProject.id}/shift_card/download`,
        {
          params: {
            ...filters,
            ...(currentPaymentTypeName !== "All" && {
              payment_type: currentPaymentTypeName,
            }),
          },
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendShiftCards({ rootState }, { email, filters }) {
    const { currentProject, currentPaymentTypeName } =
      rootState.applicationState;

    try {
      const { data } = await apiClient.get(
        `/api/report/project/${currentProject.id}/shift_card/send_email/${email}`,
        {
          params: {
            ...filters,
            ...(currentPaymentTypeName !== "All" && {
              payment_type: currentPaymentTypeName,
            }),
          },
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async downloadExternalSystem({ rootState }, { start, end }) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.get(
        `/api/report/project/${currentProject.id}/external_system/${start}/${end}/download`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendExternalSystem({ rootState }, { start, end, email }) {
    const { currentProject } = rootState.applicationState;

    try {
      const { data } = await apiClient.get(
        `/api/report/project/${currentProject.id}/external_system/${start}/${end}/send_email/${email}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
};
