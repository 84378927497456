var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"New Associate","width":"1000px","visible":_vm.visible,"footer":null,"destroy-on-close":true},on:{"cancel":_vm.closeModal}},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"content-view"},[_c('div',{staticClass:"content-view-block"},[_c('h3',[_vm._v("Personal Information")]),_c('p',[_c('b',[_vm._v("First Name "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'firstName',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.firstNameSuggestionMsg,
                      whitespace: true,
                    },
                    { pattern: _vm.namePattern, message: _vm.nameErrorMsg },
                  ],
                },
              ]),expression:"[\n                'firstName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: firstNameSuggestionMsg,\n                      whitespace: true,\n                    },\n                    { pattern: namePattern, message: nameErrorMsg },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Enter first name"}})],1)],1),_c('p',[_c('b',[_vm._v("Last Name "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'lastName',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.lastNameSuggestionMsg,
                      whitespace: true,
                    },
                    { pattern: _vm.namePattern, message: _vm.nameErrorMsg },
                  ],
                },
              ]),expression:"[\n                'lastName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: lastNameSuggestionMsg,\n                      whitespace: true,\n                    },\n                    { pattern: namePattern, message: nameErrorMsg },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Enter last name"}})],1)],1),_c('p',[_c('b',[_vm._v("Email "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  rules: [
                    { required: true, message: _vm.emailSuggestionMsg },
                    { type: 'email', message: _vm.emailErrorMsg },
                  ],
                },
              ]),expression:"[\n                'email',\n                {\n                  rules: [\n                    { required: true, message: emailSuggestionMsg },\n                    { type: 'email', message: emailErrorMsg },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Enter email"}})],1)],1),_c('p',[_c('b',[_vm._v("Phone "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone',
                {
                  rules: [
                    { required: true, message: _vm.phoneSuggestionMsg },
                    { pattern: /^\+\d{10,15}$/, message: _vm.phoneErrorMsg },
                  ],
                },
              ]),expression:"[\n                'phone',\n                {\n                  rules: [\n                    { required: true, message: phoneSuggestionMsg },\n                    { pattern: /^\\+\\d{10,15}$/, message: phoneErrorMsg },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Enter phone"}})],1)],1),_c('h3',[_vm._v("Company Information")]),_c('p',[_c('b',{staticClass:"associate-number-label"},[_vm._v("Associate Number "),_c('span',{staticClass:"required-field"},[_vm._v("*")])]),_c('a-form-item',{attrs:{"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'associateNumber',
                {
                  rules: [
                    { required: true, message: _vm.associateNumberSuggestionMsg },
                  ],
                },
              ]),expression:"[\n                'associateNumber',\n                {\n                  rules: [\n                    { required: true, message: associateNumberSuggestionMsg },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Enter associate number"},on:{"input":_vm.handleAssociateNumberChange}})],1)],1),_c('div',{staticClass:"view-button-group"},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingForm,"disabled":_vm.enabledCreateButton}},[_vm._v("Create ")])],1)],1)]),_c('div',{staticClass:"content-view-block"},[_c('p',[_c('b',[_vm._v("Schedule Type")]),_c('a-form-item',{attrs:{"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'scheduleType',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Please select a schedule type!',
                    },
                  ],
                  initialValue: undefined,
                },
              ]),expression:"[\n                'scheduleType',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please select a schedule type!',\n                    },\n                  ],\n                  initialValue: undefined,\n                },\n              ]"}],attrs:{"placeholder":"Select ST"}},_vm._l((_vm.scheduleTypes),function(type){return _c('a-select-option',{key:type.name,attrs:{"value":type.name}},[_vm._v(_vm._s(type.label)+" ")])}),1)],1)],1),_c('h3',[_vm._v("Payment Information")]),_c('p',[_c('b',[_vm._v("Payment Type")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'paymentType',
                { rules: [{ required: false }], initialValue: undefined },
              ]),expression:"[\n                'paymentType',\n                { rules: [{ required: false }], initialValue: undefined },\n              ]"}],attrs:{"placeholder":"Select PT"},on:{"change":_vm.handlePaymentTypeChange}},_vm._l((_vm.paymentTypes),function(type){return _c('a-select-option',{key:type.id,attrs:{"value":type.id}},[_vm._v(_vm._s(type.name)+" ")])}),1)],1)],1),_c('p',[_c('b',[_vm._v("Payment Status")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'paymentStatus',
                { rules: [{ required: false }], initialValue: undefined },
              ]),expression:"[\n                'paymentStatus',\n                { rules: [{ required: false }], initialValue: undefined },\n              ]"}],attrs:{"placeholder":"Select Payment Status"}},_vm._l((_vm.paymentStatuses),function(status){return _c('a-select-option',{key:status.id,attrs:{"value":status.id}},[_vm._v(_vm._s(status.label)+" ")])}),1)],1)],1),(_vm.isEmployeePaymentTypeSelected && _vm.isFullTimePaymentStatus)?_c('p',[_c('b',[_vm._v("Payment Method")]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'paymentMethod',
                { rules: [{ required: false }], initialValue: undefined },
              ]),expression:"[\n                'paymentMethod',\n                { rules: [{ required: false }], initialValue: undefined },\n              ]"}],attrs:{"placeholder":"Select PM"}},_vm._l((_vm.paymentMethods),function(method){return _c('a-select-option',{key:method.id,attrs:{"value":method.id}},[_vm._v(_vm._s(method.label)+" ")])}),1)],1)],1):_vm._e(),(_vm.isEmployeePaymentTypeSelected)?_c('p',[_c('b',[_vm._v("Rate, $")]),_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'rate',
                { rules: [{ required: false }], initialValue: undefined },
              ]),expression:"[\n                'rate',\n                { rules: [{ required: false }], initialValue: undefined },\n              ]"}],attrs:{"placeholder":"Enter rate","min":0}})],1)],1):_vm._e(),(_vm.isContractorPaymentTypeSelected)?_c('p',[_c('b',[_vm._v("Hourly Rate, $")]),_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'rate',
                { rules: [{ required: false }], initialValue: undefined },
              ]),expression:"[\n                'rate',\n                { rules: [{ required: false }], initialValue: undefined },\n              ]"}],attrs:{"placeholder":"Enter rate","min":0}})],1)],1):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }