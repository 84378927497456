<template>
  <div>
    <h2 class="view-header">Visitors</h2>

    <div class="table-controls">
      <div class="controls-wrapper">
        <div class="filters">
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showVisitorFilterModal"
          >
            Filters
          </a-button>
          <a-button
            icon="undo"
            :disabled="loading || filtersAreEmpty"
            @click="resetVisitorFilters"
          >
            Reset Filters
          </a-button>
        </div>
      </div>
    </div>

    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :loading="loading"
      :rowKey="record => record.id"
      @change="handleTableChange"
    />

    <visitor-filters-modal
      :visible.sync="visitorFiltersVisible"
      :filters="filters"
      @apply-filters="applyFilters"
    />
  </div>
</template>

<script>
import { Table, Button, Modal, Form, Input, Select, DatePicker, Spin } from "ant-design-vue";
import api from "@/api";
import visitorFiltersModal from "@/components/visitor-filters-modal.vue";
import moment from 'moment';

export default {
  components: {
    "a-button": Button,
    "a-table": Table,
    "a-modal": Modal,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-range-picker": DatePicker.RangePicker,
    "a-spin": Spin,
    visitorFiltersModal,
  },
  mixins: [api],
  data() {
    return {
      tableData: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
      },
      filters: {
        visitDate: null,
        type: null,
        name: "",
      },
      loading: false,
      visitorFiltersVisible: false,
      sortOrder: null,
      sortField: null,
    };
  },
  computed: {
    filtersAreEmpty() {
      return !this.filters.visitDate && !this.filters.type && !this.filters.name;
    },
    columns() {
      return [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          sorter: this.sortById,
          defaultSortOrder: "ascend",
        },
        {
          title: "Visitor",
          dataIndex: "visitor",
          key: "visitor",
          sorter: this.sortByVisitor,
          customRender: (text, record) => this.getFullName(record.first_name, record.last_name),
        },
        {
          title: "Phone",
          dataIndex: "phone",
          key: "phone",
          sorter: this.sortByPhone,
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          sorter: this.sortByType,
        },
        {
          title: "Visit Date",
          dataIndex: "visit_date",
          key: "visit_date",
          sorter: this.sortByVisitDate,
          customRender: (text, record) => moment(record.visit_date).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          title: "Vendor Name",
          dataIndex: "vendor_name",
          key: "vendor_name",
          sorter: this.sortByVendorName,
          customRender: (text, record) => record.vendor_name || 'N/A',
        },
        {
          title: "Destination",
          dataIndex: "destination",
          key: "destination",
          sorter: this.sortByDestination,
        },
        {
          title: "Reason",
          dataIndex: "reason",
          key: "reason",
          sorter: this.sortByReason,
        },
      ];
    },
  },
  methods: {
    sortById(stringA, stringB) {
      return stringA.id - stringB.id;
    },
    sortByVisitor(stringA, stringB) {
      const visitorA = stringA.visitor || '';
      const visitorB = stringB.visitor || '';
      return visitorA.localeCompare(visitorB);
    },
    sortByPhone(stringA, stringB) {
      const phoneA = stringA.phone || '';
      const phoneB = stringB.phone || '';
      return phoneA.localeCompare(phoneB);
    },
    sortByType(stringA, stringB) {
      const typeA = stringA.type || '';
      const typeB = stringB.type || '';
      return typeA.localeCompare(typeB);
    },
    sortByVisitDate(stringA, stringB) {
      const dateA = stringA.visit_date ? moment(stringA.visit_date).valueOf() : 0;
      const dateB = stringB.visit_date ? moment(stringB.visit_date).valueOf() : 0;
      return dateA - dateB;
    },
    sortByVendorName(stringA, stringB) {
      const nameA = stringA.vendor_name || '';
      const nameB = stringB.vendor_name || '';
      return nameA.localeCompare(nameB);
    },
    sortByDestination(stringA, stringB) {
      const destinationA = stringA.destination ? stringA.destination.trim() : '';
      const destinationB = stringB.destination ? stringB.destination.trim() : '';
      return destinationA.localeCompare(destinationB);
    },
    sortByReason(stringA, stringB) {
      const reasonA = stringA.reason || '';
      const reasonB = stringB.reason || '';
      return reasonA.localeCompare(reasonB);
    },
    async loadData() {
      this.loading = true;

      const visitDateFrom = this.filters.visitDate && this.filters.visitDate[0] ? this.filters.visitDate[0].format('YYYY-MM-DD') : null;
      const visitDateTo = this.filters.visitDate && this.filters.visitDate[1] ? this.filters.visitDate[1].format('YYYY-MM-DD') : null;

      const filterData = {
        visit_date_from: visitDateFrom,
        visit_date_to: visitDateTo,
        type: this.filters.type || null,
        name: this.filters.name || "",
      };

      const params = {
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
        project_id: this.$store.state.applicationState.currentProject.id,
        filter: filterData,
        sort_field: this.sortField,
        sort_order: this.sortOrder,
      };

      try {
        const response = await this.apiGetVisitors(params);
        const data = await response.json();

        if (!data || !data.items || data.items.length === 0) {
          this.tableData = [];
          return;
        }

        this.tableData = data.items;

        this.pagination.total = data.total_count || 0;
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        this.loading = false;
      }
    },

    showVisitorFilterModal() {
      this.visitorFiltersVisible = true;
    },

    resetVisitorFilters() {
      this.filters = {
        visitDate: null,
        type: null,
        name: "",
      };
      this.pagination.current = 1;
      this.loadData();
    },

    applyFilters(updatedFilters) {
      this.filters = updatedFilters;
      this.visitorFiltersVisible = false;
      this.pagination.current = 1;
      this.loadData();
    },

    handleTableChange(pagination, filters, sorter) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;

        if (sorter.field) {
          this.sortField = sorter.field;
          this.sortOrder = sorter.order;
        }

      this.loadData();
    },
    getFullName(firstName, lastName) {
      return firstName && lastName ? `${firstName} ${lastName}` : 'Unknown Visitor';
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.table-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.filters {
  display: flex;
  gap: 10px;
}
</style>
