import store from "@/store";
import AlertStore from "@/views/alert-store.vue";
import AssociateOverview from "@/views/associate-activity.vue";
import AssociatePayCodes from "@/modules/shifts/views/shifts-pay-codes.vue";
import AssociateProfiles from "@/views/associate-profiles.vue";
import EmplRep from "@/views/associate-report.vue";
import Auth from "@/views/auth.vue";
import CreateUserWithAssociate from "@/views/create-user-with-associate.vue";
import GeneralDailyReport from "@/views/daily-summary.vue";
import Dev from "@/views/dev.vue";
import Documents from "@/views/documents.vue";
import MispunchReport from "@/views/mispunch-report.vue";
import OvertimeReport from "@/views/overtime-report.vue";
import PayrollSystemIdReport from "@/views/payroll-system-id-report.vue";
import PbjReport from "@/views/pbj-report.vue";
import ProjectShiftAuths from "@/modules/shifts/views/shift-authorizations.vue";
import Project from "@/views/project.vue";
import Mailing from "@/views/mailing.vue";
import SignedShiftsReport from "@/views/signed-shifts-report.vue";
import TimeOffRequests from "@/views/time-off-requests.vue";
import TotalHoursDetailReport from "@/views/total-hours-detail-report.vue";
import WorkedShiftsReport from "@/views/worked-shifts-report.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import TimeCard from "../views/associate-shift-old.vue";
import TimeCards from "../modules/shifts/views/shifts.vue";
import PayCodes from "../modules/paycode/views/pay-codes.vue";
import ShiftAuth from "../views/shift-authorization.vue";
import Shifts from "../views/shifts.vue";
import Visitors from "../views/visitors.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "home",
    component: Project,
    props: true,
    meta: { title: "Company - ePeople Time" },
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    props: true,
    meta: { title: "ePeople Time" },
  },
  {
    path: "/shifts",
    name: "shifts",
    component: TimeCards,
    props: (route) => {
      const normalizeToArray = (param) =>
        Array.isArray(param) ? param : param ? [param] : undefined;

      return {
        filterNew: {
          end_date: route.query.end_date,
          start_date: route.query.start_date,
          role: route.query.role,
          associate_ids: normalizeToArray(route.query.associate_ids),
          statuses: normalizeToArray(route.query.statuses),
          payment_statuses: normalizeToArray(route.query.payment_statuses),
          payment_type: route.query.payment_type,
          shift_type: route.query.shift_type,
          manually_created: route.query.manually_created,
          with_pay_codes: route.query.with_pay_codes,
          pay_code: route.query.pay_code,
          attached_pay_code: route.query.attached_pay_code,
          with_files: route.query.with_files,
          adjusted: route.query.adjusted,
        },
      };
    },
    meta: { title: "Associate Shifts - ePeople Time" },
  },
  {
    path: "/time-card",
    name: "time-card",
    component: TimeCard,
    props: true,
    meta: { title: "Shift - ePeople Time" },
  },
  {
    path: "/associate-pay-codes",
    name: "associate-pay-codes",
    component: AssociatePayCodes,
    meta: { title: "Associate Pay Codes - ePeople Time" },
  },
  {
    path: "/pay-codes",
    name: "pay-codes",
    component: PayCodes,
    props: true,
    meta: { title: "Pay Codes - ePeople Time" },
  },
  {
    path: "/shift-authorization",
    name: "shift-authorization",
    component: ShiftAuth,
    props: true,
    meta: { title: "Shift Authorization - ePeople Time" },
  },
  {
    path: "/shifts-dictionary",
    name: "shifts-dictionary",
    component: Shifts,
    props: true,
    meta: { title: "Shifts Dictionary - ePeople Time" },
  },
  {
    path: "/payments-request",
    name: "payments-request",
    component: () => import("../modules/payment/views/payments.vue"),
    props: (route) => ({
      filter: {
        associates: route.query.associates,
        status: route.query.status,
        system: route.query.system,
      },
    }),
    meta: { title: "Payments - ePeople Time" },
  },
  {
    path: "/payments-settings",
    name: "payments-settings",
    component: () => import("../views/payments-settings.vue"),
    meta: { title: "Payments Settings - ePeople Time" },
  },
  // {
  //   path: '/attendance-report',
  //   name: 'attendance-report',
  //   component: () => import('../views/attendance-report'),
  //   props: true,
  //   meta: { title: 'Attendance Report - ePeople Time' }
  // },
  {
    path: "/associate-report",
    name: "associate-report",
    component: EmplRep,
    props: true,
    meta: { title: "Associate Report - ePeople Time" },
  },
  {
    path: "/overtime-report",
    name: "overtime-report",
    component: OvertimeReport,
    props: true,
    meta: { title: "Overtime Report - ePeople Time" },
  },
  {
    path: "/total-hours-detail-report",
    name: "total-hours-detail-report",
    component: TotalHoursDetailReport,
    props: true,
    meta: { title: "Total Hours Detail - ePeople Time" },
  },
  {
    path: "/mispunch-report",
    name: "mispunch-report",
    component: MispunchReport,
    props: true,
    meta: { title: "Mispunch Report - ePeople Time" },
  },
  {
    path: "/signed-shifts-report",
    name: "signed-shifts-report",
    component: SignedShiftsReport,
    props: true,
    meta: { title: "Signed Shifts Report - ePeople Time" },
  },
  {
    path: "/roles",
    name: "roles",
    component: () => import("../modules/roles/view/roles.vue"),
    props: true,
    meta: { title: "Job Classifications Dictionary - ePeople Time" },
  },
  {
    path: "/agencies",
    name: "agencies",
    component: () => import("../views/agencies.vue"),
    props: true,
    meta: { title: "Agencies Dictionary - ePeople Time" },
  },
  {
    path: "/not-in-work-area",
    name: "not-in-work-area",
    component: () => import("../views/associate-not-in-wa.vue"),
    props: true,
    meta: { title: "Not in Work Area Report - ePeople Time" },
  },
  // {
  //   path: "/associate-questionary",
  //   name: "associate-questionary",
  //   component: () => import("../views/questionary.vue"),
  //   props: true,
  //   meta: { title: "Associate Questionary Report - ePeople Time" },
  // },
  {
    path: "/active-associates",
    name: "active-associates",
    component: () => import("../views/active-associates.vue"),
    props: true,
    meta: { title: "Active Associates - ePeople Time" },
  },
  {
    path: "/time-attendance-detail-report",
    name: "time-attendance-detail-report",
    component: () => import("../views/time-attendance-detail-report.vue"),
    props: true,
    meta: { title: "Time Attendance Detail Report - ePeople Time" },
  },
  {
    path: "/pbj-report",
    name: "pbj-report",
    component: PbjReport,
    props: true,
    meta: { title: "PBJ Report - ePeople Time" },
  },
  {
    path: "/worked-shifts-report",
    name: "worked-shifts-report",
    component: WorkedShiftsReport,
    props: true,
    meta: { title: "Worked Shifts Report - ePeople Time" },
  },
  {
    path: "/payroll-system-id-report",
    name: "payroll-system-id-report",
    component: PayrollSystemIdReport,
    props: true,
    meta: { title: "Payroll System ID Report - ePeople Time" },
  },
  {
    path: "/external-system-report",
    name: "external-system-report",
    component: () =>
      import("../modules/report/views/report-payment-system.vue"),
    props: true,
    meta: { title: "External System Report - ePeople Time" },
  },
  {
    path: "/documents",
    name: "documents",
    component: Documents,
    props: true,
    meta: { title: "Documents Upload - ePeople Time" },
  },
  {
    path: "/mailing",
    name: "mailing",
    component: Mailing,
    props: true,
    meta: { title: "Mailing - ePeople Time" },
  },
  {
    path: "/visitors",
    name: "visitors",
    component: Visitors,
    props: true,
    meta: { title: "Visitors - ePeople Time" },
  },
  {
    path: "/payroll",
    name: "payroll",
    component: () => import("../modules/payroll/views/payroll.vue"),
    props: (route) => ({
      tab: route.query.tab ?? "shifts",
      id: route.query.id,
    }),
    beforeEnter(to, from, next) {
      store.commit("payroll/resetState");
      next();
    },
    meta: { title: "Payroll - ePeople Time" },
  },
  {
    path: "/active-payroll",
    name: "active-payroll",
    component: () => import("../modules/payroll/views/active-payroll.vue"),
    props: (route) => ({
      tab: route.query.tab ?? "associates",
    }),
    beforeEnter(to, from, next) {
      store.commit("payroll/resetState");
      next();
    },
    meta: { title: "Active Payroll - ePeople Time" },
  },
  {
    path: "/past-payrolls",
    name: "past-payrolls",
    component: () => import("../modules/payroll/views/payroll-history.vue"),
    props: true,
    meta: { title: "Past Payrolls - ePeople Time" },
  },
  {
    path: "/payroll-associate-shifts",
    name: "payroll-associate-shifts",
    component: () =>
      import("../modules/payroll/views/payroll-associate-shifts.vue"),
    props: (route) => ({
      associateId: route.query.associateId,
      payrollId: route.query.payrollId,
    }),
    beforeEnter: (to, from, next) => {
      const fromActivePayroll =
        from.name === "active-payroll" || from.name === "payroll";

      if (!to.query.associateId || !to.query.payrollId) {
        next({ name: "active-payroll" });
      } else if (!fromActivePayroll) {
        next();
      } else {
        next();
      }
    },
    meta: { title: "Payroll Associate Shifts - ePeople Time" },
  },
  {
    path: "/payroll-associate-payments",
    name: "payroll-associate-payments",
    component: () =>
      import("../modules/payroll/views/payroll-associate-payments.vue"),
    props: (route) => ({
      associateId: route.query.associateId,
      payrollId: route.query.payrollId,
    }),
    beforeEnter: (to, from, next) => {
      const fromActivePayroll =
        from.name === "active-payroll" || from.name === "payroll";

      if (!to.query.associateId || !to.query.payrollId) {
        next({ name: "active-payroll" });
      } else if (!fromActivePayroll) {
        next();
      } else {
        next();
      }
    },
    meta: { title: "Payroll Associate Payment Requests - ePeople Time" },
  },
  {
    path: "/project-shift-auths",
    name: "project-shift-auths",
    component: ProjectShiftAuths,
    props: true,
    meta: { title: "Shift Authorizations - ePeople Time" },
  },
  // {
  //   path: '/manual-shift-auths',
  //   name: 'manual-shift-auths',
  //   component: ManualShiftAuths,
  //   props: true,
  //   meta: { title: 'Manual Shift Authorizations - ePeople Time' }
  // },
  {
    path: "/associate-profiles",
    name: "associate-profiles",
    component: AssociateProfiles,
    props: (route) => ({
      filters: {
        role: route.query.role,
        associateNumber: route.query.associateNumber,
        scheduleType: route.query.scheduleType,
        isActive: route.query.isActive,
        isVerified: route.query.isVerified,
      },
      editingAssociate: route.query.editingAssociate,
    }),
    meta: { title: "Associate Profiles - ePeople Time" },
  },
  {
    path: "/create-user-with-associate",
    name: "create-user-with-associate",
    component: CreateUserWithAssociate,
    props: true,
    meta: { title: "Create User With Associate - ePeople Time" },
  },
  {
    path: "/daily-summary",
    name: "daily-summary",
    component: GeneralDailyReport,
    props: true,
    meta: { title: "Daily Summary - ePeople Time" },
  },
  {
    path: "/activity",
    name: "activity",
    component: AssociateOverview,
    props: true,
    meta: { title: "Associate Activity - ePeople Time" },
  },
  {
    path: "/time-off",
    name: "time-off",
    component: TimeOffRequests,
    props: true,
    meta: { title: "Time Off Requests - ePeople Time" },
  },
  {
    path: "/shift-card-report",
    name: "shift-card-report",
    component: () => import("../modules/report/views/report-shift-cards.vue"),
    props: true,
    meta: { title: "Shift Card Report - ePeople Time" },
  },
  {
    path: "/alert-store",
    name: "alert-store",
    component: AlertStore,
    props: true,
    meta: { title: "Alert Store - ePeople Time" },
  },

  {
    path: "/dev",
    name: "dev",
    component: Dev,
    props: true,
    meta: { title: "Dev - ePeople Time" },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const hasToken = store.state.applicationState.token;

  // Check if the user is trying to access a protected route without a token
  if (!hasToken && to.path !== "/auth") {
    return next("/auth");
  }

  document.title =
    to.meta != null && to.meta.title != null ? to.meta.title : "ePeople Time";

  // If the user is on the /auth route and has a token, redirect to the home page
  if (to.path === "/auth" && hasToken) {
    return next("/");
  }

  next();
});

export default router;
