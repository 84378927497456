<template>
  <div class="grid">
    <send-modal
      :visible="visibleSendModal"
      :loading="loadingSend"
      @close="closeSendModal"
      @send="sendReport"
    />
    <div class="col">
      <h3>Info</h3>
      <a-descriptions :column="1" size="small" class="col-fixed p-0">
        <a-descriptions-item label="Date">
          {{ formattedDate }}
        </a-descriptions-item>
        <a-descriptions-item v-if="shift.version" label="Version">
          {{ shift.version }} </a-descriptions-item
        ><a-descriptions-item label="Status">
          <div class="flex flex-wrap justify-content-end gap-2">
            <a-tag class="m-0" v-if="shift.pay_code" color="#d47aff">
              {{ shift.pay_code.name }}
            </a-tag>

            <a-tag
              v-if="!shift.pay_code || shift.deleted_by"
              class="m-0"
              :color="getShiftStatus(shift.status).color"
            >
              {{ getShiftStatus(shift.status).title }}
            </a-tag>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="Deleted By" v-if="shift.deleted_by">
          <grouped-associate-info
            :first-name="shift.deleted_by.first_name"
            :last-name="shift.deleted_by.last_name"
            :photo-url="shift.deleted_by.photo_url"
            :associate-number="shift.deleted_by.associate_number"
        /></a-descriptions-item>
        <a-descriptions-item label="Source">
          {{ shift.origin_label }}
        </a-descriptions-item>
        <a-descriptions-item v-if="shift.author" label="Author">
          {{ shift.author.first_name }}
          {{ shift.author.last_name }}</a-descriptions-item
        >
        <a-descriptions-item v-if="!shift.pay_code" label="Payment Status">
          <a-tag
            class="m-0"
            :color="getShiftPaymentRequestStatus(shift.payment_status).color"
            >{{
              getShiftPaymentRequestStatus(shift.payment_status).title
            }}</a-tag
          >
        </a-descriptions-item>
        <a-descriptions-item label="Payment Type">
          {{ shift.payment_type }}
        </a-descriptions-item>
        <a-descriptions-item label="Rate">
          ${{ shift.hour_rate / 100 }}</a-descriptions-item
        >
      </a-descriptions>
    </div>
    <div class="col">
      <h3>Digital Signature</h3>
      <signature
        v-if="shift.signature"
        :associate="signatureAssociate"
        :available-signature="shift.signature"
      />

      <span v-else>Signature not provided</span>
    </div>
    <div class="col">
      <h3>Reports</h3>
      <a-card title="Shift Time Card">
        <div class="flex gap-2">
          <a-button
            :loading="loadingSend"
            icon="mail"
            type="primary"
            @click="openSendModal"
          >
            Send To Email
          </a-button>
          <a-button
            :loading="loadingDownload"
            icon="upload"
            type="primary"
            @click="downloadReport"
          >
            Download
          </a-button>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Card,
  Descriptions,
  notification,
  Table,
  Tag,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import Signature from "@/components/signature.vue";
import Util from "@/util";
import moment from "moment-timezone";
import SendModal from "@/components/send-modal.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";

const {
  mapState: shiftState,
  mapGetters: shiftGetters,
  mapActions: shiftActions,
} = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-tag": Tag,
    "a-card": Card,
    "send-modal": SendModal,
    "grouped-associate-info": GroupedAssociateInfo,
    signature: Signature,
  },
  data() {
    return {
      visibleSendModal: false,

      loadingSend: false,
      loadingDownload: false,
    };
  },
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
    }),
    ...shiftGetters(["getShiftPaymentRequestStatus", "getShiftStatus"]),

    signatureAssociate() {
      return {
        name: Util.combineAssociateName(
          this.shift.signature.first_name,
          this.shift.signature.last_name
        ),
        email: this.shift.signature.email,
        phone: this.shift.signature.phone,
      };
    },

    formattedDate() {
      const start = moment(this.shift.scheduled_start).utc(true);
      const end = moment(this.shift.scheduled_end).utc(true);

      let result = "";

      result = `${start.format(this.$store.state.applicationState.dateFormat)}`;

      if (!start.isSame(end, "day")) {
        result += ` - ${end.format(
          this.$store.state.applicationState.dateFormat
        )}`;
      }

      return result;
    },
  },
  methods: {
    ...shiftActions([
      "sendReportShiftTimeCards",
      "downloadReportShiftTimeCards",
    ]),

    closeSendModal() {
      this.visibleSendModal = false;
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openSendModal() {
      this.visibleSendModal = true;
    },

    async sendReport(email) {
      this.closeSendModal();

      this.loadingSend = true;

      const payload = {
        shift_id: this.shift.id,
        email,
      };

      try {
        await this.sendReportShiftTimeCards(payload);

        this.showNotification(
          "success",
          "Success",
          "Shift Time Card Report successfully sent to email"
        );
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.loadingSend = false;
      }
    },

    async downloadReport() {
      this.loadingDownload = true;

      try {
        const url = await this.downloadReportShiftTimeCards({
          shift_id: this.shift.id,
        });

        window.open(url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.loadingDownload = false;
      }
    },
  },
};
</script>

<style scoped>
.ant-card {
  height: fit-content;
  border-radius: 10px;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.ant-tag {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  text-align: right !important;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  justify-content: space-between;
}
</style>
