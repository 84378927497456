<template>
  <div class="column">
    <h3>Attachments</h3>
    <div>
      <a-upload
        list-type="picture-card"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :remove="handleRemove"
        :custom-request="handleUpload"
        @preview="handlePreview"
        @change="handleChange"
      >
        <div>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">Upload</div>
        </div>
      </a-upload>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Button, Icon, Upload } from "ant-design-vue";

export default {
  components: {
    "a-upload": Upload,
    "a-button": Button,
    "a-icon": Icon,
  },
  props: {
    files: {
      type: Array,
      default: [],
    },
  },
  mixins: [api],
  data() {
    return {
      previewVisible: false,

      previewImage: "",
      currentFile: {},
      fileList: [],

      loading: false,
    };
  },
  watch: {
    files: {
      handler(newVal) {
        this.fileList = newVal.map((item) => ({
          uid: item.id,
          name: item.url,
          url: item.url,
          status: "done",
        }));
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
      this.$emit(
        "save",
        this.fileList.map((item) => ({ id: item.uid }))
      );
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    beforeUpload(file) {
      if (!file.type.startsWith("image")) {
        notification.error({
          message: "Error",
          description: "You can only upload image files",
        });
        return false;
      }
      return true;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleUpload({ file, onSuccess, onError }) {
      this.loading = true;

      try {
        const { data } = await this.apiUploadFiles([file]);

        if (!data || !data.ids || !data.ids.length) {
          throw new Error("Error occurred while uploading files.");
        }

        const fileData = { id: data.ids[0] };

        this.fileList = [
          ...this.fileList,
          {
            ...this.currentFile,
            uid: fileData.id,
            status: "done",
          },
        ];

        this.$emit(
          "save",
          this.fileList.map((item) => ({ id: item.uid }))
        );

        onSuccess(fileData);
      } catch (error) {
        onError(error);
      } finally {
        this.loading = false;
        this.currentFile = {};
      }
    },

    handleChange({ file }) {
      this.currentFile = file;
    },
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
