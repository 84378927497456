export const PAYROLL_STATUS = Object.freeze({
  created: {
    title: "In Progress",
    color: "#52bfff",
  },
  completed: {
    title: "Prepared",
    color: "#6C69FF",
  },
  sign_completed: {
    title: "Awaiting payment",
    color: "#34db30",
  },
  paid: {
    title: "Paid",
    color: "#34db30",
  },
});

export const ADJUSTMENT_STATUS = Object.freeze({
  initial: {
    title: "Initial",
    color: "#52bfff",
  },
  validated: {
    title: "Validated",
    color: "#34db30",
  },
});

export const ASSOCIATE_STATUS = Object.freeze({
  initial: {
    title: "Unverified",
    color: "#ffb546",
  },
  started: {
    title: "Started",
    color: "#52bfff",
  },
  validated: {
    title: "Verified",
    color: "#33dfcf",
  },
  signed: {
    title: "Signed",
    color: "#34db30",
  },
  admin_signed: {
    title: "Admin Signed",
    color: "#6C69FF",
  },
});

export const INITIAL_STATE = {
  payrolls: [],

  metrics: {},

  adjustments: [],

  adjustmentsShifts: {},

  payPeriods: [],

  activePayroll: null,
  loadingActivePayroll: false,

  shifts: {
    paid: {},
    ready_to_pay: {},
    problematic: {},
  },

  paymentRequests: {},

  calculations: [],

  validationAssociates: {},
  signedAssociates: {},
  unsignedAssociates: {},

  associateShifts: {},
  associatePayments: {},
  associateCalculations: [],
};
