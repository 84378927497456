<template>
  <div class="column">
    <h3>Shift Times</h3>
    <div>
      <a-tabs default-active-key="signed" size="large">
        <a-tab-pane key="signed" tab="Signed">
          <shift-time :times="signedTime" />
        </a-tab-pane>
        <a-tab-pane key="actual" tab="Actual">
          <shift-time :times="actualTime" />
        </a-tab-pane>
        <a-tab-pane key="scheduled" tab="Scheduled">
          <shift-time :times="scheduledTime" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Card, Tabs } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import shiftsHelper from "@/helpers/shifts";
import validation from "@/modules/shifts/mixins/ShiftValidationMixin";
import ShiftTime from "./shift-time.vue";

const { mapState: shiftState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-card": Card,
    "a-tabs": Tabs,
    "a-tab-pane": Tabs.TabPane,
    "shift-time": ShiftTime,
  },
  mixins: [shiftsHelper, validation],
  data() {
    return {};
  },
  computed: {
    ...shiftState({
      shiftForm: (state) => state.shiftForm,
    }),

    scheduledTime() {
      return this.generateTimeData("scheduled_");
    },

    actualTime() {
      return this.generateTimeData("actual_");
    },

    signedTime() {
      return this.generateTimeData("");
    },
  },
  methods: {
    generateTimeData(prefix) {
      return {
        scheduled_start: this.shiftForm.scheduled_start,
        scheduled_end: this.shiftForm.scheduled_end,
        start:
          prefix === "scheduled_"
            ? this.shiftForm[`${prefix}start`]
            : this.shiftForm[`${prefix}work_start`],
        end:
          prefix === "scheduled_"
            ? this.shiftForm[`${prefix}end`]
            : this.shiftForm[`${prefix}work_end`],
        duration: this.shiftForm[`${prefix}duration`],
        lunch_start: this.shiftForm[`${prefix}lunch_start`] || null,
        lunch_end: this.shiftForm[`${prefix}lunch_end`] || null,
        lunch_duration: this.shiftForm[`${prefix}lunch_duration`] || null,
        second_lunch_start:
          this.shiftForm[`${prefix}second_lunch_start`] || null,
        second_lunch_end: this.shiftForm[`${prefix}second_lunch_end`] || null,
        second_lunch_duration:
          this.shiftForm[`${prefix}second_lunch_duration`] || null,
      };
    },
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p {
  display: flex;
  justify-content: space-between;
}

.ant-tabs {
  border: 1px solid #e8e8e8;
}

::v-deep .ant-tabs-bar {
  margin: 0;
}
</style>
