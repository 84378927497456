<template>
  <div>
    <h2 class="view-header">
      <span class="flex gap-2 align-items-center justify-content-center">
        Associate Shifts
        <a-tooltip placement="right">
          <template slot="title"> Start Tour </template>
          <a-icon
            @click.stop="startTour()"
            class="flex align-items-center cursor-pointer"
            type="question-circle"
            theme="twoTone"
          />
        </a-tooltip>
      </span>
    </h2>
    <v-tour
      name="shiftsTour"
      :steps="tourSteps"
      :callbacks="callbacks"
      :options="vueTourOptions"
    />
    <div>
      <associate-shifts-filters
        :current-filters="filterNew"
        :visible.sync="visibleFiltersModal"
        @apply="applyFilters"
      />

      <shift-pay-code
        :initial-values="initialValues"
        :visible.sync="visiblePayCodeModal"
        @add="fetchShifts"
      />

      <shift-view
        :visible.sync="visibleShiftModal"
        :shift_id="editingShiftId"
        @edit="fetchShifts"
      />

      <a-modal
        title="Associate Profile Edit"
        width="80%"
        :footer="null"
        v-model="visibleProfileModal"
        @ok="endEditAssociateProfile"
        @cancel="endEditAssociateProfile"
        :destroy-on-close="true"
      >
        <div style="overflow-x: auto; width: 100%">
          <associate-profile
            :associate-number.sync="editingAssociateNumber"
            @edited="fetchShifts"
            @clone="openCloneAssociate"
            @close="endEditAssociateProfile"
          />
        </div>
      </a-modal>

      <a-modal
        v-model="visibleCloneModal"
        title="Clone Associate"
        width="1000px"
        :footer="null"
        :destroy-on-close="true"
      >
        <clone-associate
          :associate-number="cloningAssociateNumber"
          @close="endCloneAssociate"
        />
      </a-modal>

      <shift-sign
        :shift_id="signShiftId"
        :visible.sync="visibleSignModal"
        @edit="afterSignShift"
      />

      <add-multiple-shifts
        :visible="visibleAddMultipleShiftsModal"
        :initial-values="initialValues"
        @close="closeMultipleShiftsModal"
      />

      <send-notification
        :associate="associateProfileToSendSms"
        @close="closeSmsModal"
      />

      <shift-edit
        :visible.sync="visibleAddShiftModal"
        :initial-values="initialValues"
        @edit="fetchShifts"
      />

      <div class="table_controls_and_data">
        <div class="flex gap-2">
          <a-button
            icon="diff"
            :disabled="loading"
            type="primary"
            @click="openPayCodeModal"
            >Add Pay Code</a-button
          >
          <a-button
            type="primary"
            icon="diff"
            :disabled="loading"
            @click="openAddShiftModal"
          >
            Add Shift
          </a-button>
          <a-button
            type="primary"
            icon="copy"
            :disabled="loading"
            @click="openMultipleShiftsModal"
          >
            Add Several Shift
          </a-button>
        </div>

        <div class="flex gap-2">
          <a-badge :count="countFilters">
            <a-button
              icon="filter"
              :disabled="loading"
              @click="openFiltersModal"
            >
              Filters
            </a-button>
          </a-badge>

          <a-button
            icon="undo"
            :disabled="loading || filtersAreEmpty"
            @click="resetAssociateShiftsFilters"
          >
            Reset Filters
          </a-button>
        </div>
      </div>
      <a-table
        size="small"
        :columns="columns"
        :rowKey="(record) => record.id"
        :data-source="shifts"
        :loading="loading"
        :style="{ whiteSpace: 'pre' }"
        :locale="{ emptyText: 'Shifts Not Found' }"
        :pagination="pagination"
        :expanded-row-render="expandedRowRender"
        :dropdown-match-select-width="false"
        :expand-row-by-click="true"
        :scroll="{ x: 1600, y: tableScrollHeight }"
        @change="handleTableChange"
      >
        <span slot="associate" slot-scope="text, record">
          <grouped-associate-info
            :first-name="record.associate.first_name"
            :last-name="record.associate.last_name"
            :photo-url="record.associate.user_avatar"
            :unverified="!record.associate.is_verified"
            :associate-number="record.associate.associate_number"
          />
        </span>
        <span class="tags-cell" slot="status" slot-scope="text, record">
          <a-tooltip v-if="record.shift.pay_code">
            <template slot="title">
              {{ record.shift.pay_code.description }}
            </template>
            <div class="flex flex-column gap-2">
              <a-tag class="auth-tag" color="#d47aff">
                {{ record.shift.pay_code.name }}
              </a-tag>
            </div>
          </a-tooltip>

          <a-tooltip v-if="!record.shift.pay_code || record.shift.deleted_by">
            <template slot="title">
              {{
                record.shift.status !== "completed"
                  ? getShiftStatus(record.shift.status).text
                  : getShiftPaymentRequestStatus(record.shift.payment_status)
                      .text
              }}
            </template>
            <div class="flex flex-column gap-2">
              <a-tag
                class="auth-tag"
                :color="
                  record.shift.status !== 'completed'
                    ? getShiftStatus(record.shift.status).color
                    : getShiftPaymentRequestStatus(record.shift.payment_status)
                        .color
                "
                @click.stop="handleTagClick(record.shift)"
              >
                {{
                  record.shift.status !== "completed"
                    ? getShiftStatus(record.shift.status).title
                    : getShiftPaymentRequestStatus(record.shift.payment_status)
                        .title
                }}
              </a-tag>
            </div>
          </a-tooltip>

          <a-tooltip v-if="record.shift.retro_shift">
            <template slot="title">
              This is a shift that has been worked or is due to be worked more
              than one month ago
            </template>
            <a-tag class="auth-tag" :color="statusTagColors.GRAY">
              Retro Shift
            </a-tag>
          </a-tooltip>
        </span>
        <span slot="authorizations" slot-scope="text, record">
          <span
            v-if="
              record.shift.authorizations_with_status &&
              record.shift.authorizations_with_status.length > 0
            "
            class="flex flex-column gap-2"
          >
            <a-tag
              class="auth-tag"
              v-for="(auth, index) in record.shift.authorizations_with_status"
              :key="index"
              :color="getShiftAuthStatusTagColor(auth)"
            >
              {{ auth.name }}
            </a-tag>
          </span>
          <span v-else>-</span>
        </span>
        <span slot="actions" slot-scope="text, record">
          <a-button-group size="small">
            <a-button
              id="open-shift-btn"
              icon="eye"
              @click.stop="openShiftModal(record.shift.id)"
            />

            <a-tooltip>
              <template slot="title"> Open Profile </template>
              <a-button
                icon="user"
                @click.stop="
                  startEditAssociateProfile(record.associate.associate_number)
                "
              />
            </a-tooltip>

            <a-tooltip>
              <template slot="title"> Send Message </template>
              <a-button
                id="send-msg-btn"
                icon="message"
                :disabled="!record.associate.phone"
                @click.stop="openSmsSendModal(record.associate)"
              />
            </a-tooltip>
          </a-button-group>
        </span>
        <span slot="origin" slot-scope="text, record">
          <a-tag :color="getOriginColor(record.shift.origin_label)">
            {{ record.shift.origin_label }}
          </a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Table,
  Tag,
  Modal,
  Tooltip,
  notification,
  Icon,
  Badge,
} from "ant-design-vue";
import SendNotification from "@/components/send-notification.vue";
import AssociateShiftsFilters from "@/modules/shifts/components/shift-filters.vue";
import AddMultipleShifts from "@/modules/shifts/components/shift-multiply-modal.vue";
import { statusTagColors, vueTourOptions } from "@/const";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import AssociateProfile from "@/components/associate-profile.vue";
import CloneAssociate from "@/components/clone-associate.vue";
import ShiftPayCodeModal from "../components/shift-pay-code-create-modal.vue";
import ShiftViewModal from "../components/shift-view-modal.vue";
import ShiftSignModal from "../components/shift-sign-modal.vue";
import shiftFormVue from "../components/shift-form.vue";
import { createNamespacedHelpers } from "vuex";
import tour from "../mixins/ShiftsTourMixin";
import table from "../mixins/ShiftsTableMixin";

const { mapActions: shiftActions, mapGetters: shiftGetters } =
  createNamespacedHelpers("shifts");

const { mapActions: profileActions } = createNamespacedHelpers("profile");

const AuthStatus = Object.freeze({
  INITIAL: "initial",
  ACCEPTED: "accepted",
  DECLINED: "declined",
});

export default {
  components: {
    SendNotification,
    "a-table": Table,
    "a-tag": Tag,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tooltip": Tooltip,
    "a-modal": Modal,
    "a-icon": Icon,
    "a-badge": Badge,
    "associate-shifts-filters": AssociateShiftsFilters,
    "add-multiple-shifts": AddMultipleShifts,
    "grouped-associate-info": GroupedAssociateInfo,
    "associate-profile": AssociateProfile,
    "clone-associate": CloneAssociate,
    "shift-pay-code": ShiftPayCodeModal,
    "shift-view": ShiftViewModal,
    "shift-sign": ShiftSignModal,
    "shift-edit": shiftFormVue,
  },

  mixins: [tour, table],
  props: {
    filterNew: {
      type: Object,
    },
  },
  data() {
    return {
      shifts: [],

      visiblePayCodeModal: false,
      visibleShiftModal: false,
      visibleAddShiftModal: false,
      visibleAddMultipleShiftsModal: false,
      visibleCloneModal: false,
      visibleSignModal: false,
      visibleFiltersModal: false,
      visibleProfileModal: false,

      editingShiftId: undefined,
      signShiftId: undefined,
      cloningAssociateNumber: undefined,
      editingAssociateNumber: undefined,
      associateProfileToSendSms: undefined,

      initialValues: {
        associateNumber: undefined,
        start: null,
        end: null,
      },

      loading: false,
    };
  },

  computed: {
    ...shiftGetters([
      "getShiftStatus",
      "getOriginColor",
      "getShiftPaymentRequestStatus",
    ]),

    statusTagColors() {
      return statusTagColors;
    },

    filtersAreEmpty() {
      return Object.values(this.filterNew).every(
        (value) => value === undefined
      );
    },

    countFilters() {
      return Object.values(this.filterNew).filter((value) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== null && value !== undefined && value !== "";
      }).length;
    },

    vueTourOptions() {
      return vueTourOptions;
    },
  },
  watch: {
    filterNew: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.pagination.current = 1;
          this.fetchShifts();

          if (newVal.associate_ids && newVal.associate_ids.length > 0) {
            const [firstAssociateId] = newVal.associate_ids;

            this.fetchAssociate(firstAssociateId);
          } else {
            this.initialValues.associateNumber = undefined;
          }

          if (newVal.end_date) {
            this.initialValues.end = newVal.end_date;
          } else {
            this.initialValues.end = null;
          }

          if (newVal.start_date) {
            this.initialValues.start = newVal.start_date;
          } else {
            this.initialValues.start = null;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...shiftActions(["getShifts"]),
    ...profileActions(["getAssociateById"]),

    // filters updating

    resetAssociateShiftsFilters() {
      this.$router.replace({ query: {} }).catch(() => {});
    },

    applyFilters(filters) {
      this.$router.replace({ query: filters }).catch(() => {});
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchShifts() {
      const payload = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        filter: this.filterNew,
      };

      this.loading = true;

      try {
        const data = await this.getShifts(payload);

        this.shifts = data.items;
        this.pagination.total = data.total_count;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchAssociate(associate_id) {
      try {
        const { associate } = await this.getAssociateById({
          associate_id: associate_id,
        });

        this.initialValues.associateNumber = associate.associate_number;
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    openPayCodeModal() {
      this.visiblePayCodeModal = true;
    },

    openShiftModal(shift_id) {
      this.visibleShiftModal = true;
      this.editingShiftId = shift_id;
    },

    openSignModal() {
      this.visibleSignModal = true;
    },

    afterSignShift() {
      this.signShiftId = undefined;
      this.fetchShifts();
    },

    getShiftAuthStatusTagColor(auth) {
      if (auth.status == AuthStatus.ACCEPTED) {
        return "rgb(55, 138, 90)";
      } else if (auth.status == AuthStatus.DECLINED) {
        return "red";
      }

      return "";
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.fetchShifts();
    },

    openSmsSendModal(record) {
      this.associateProfileToSendSms = {
        associate_number: record.associate_number,
        first_name: record.first_name,
        last_name: record.last_name,
      };
    },

    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.visibleProfileModal = true;
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
      this.visibleProfileModal = false;
    },

    openFiltersModal() {
      this.visibleFiltersModal = true;
    },

    openMultipleShiftsModal() {
      this.visibleAddMultipleShiftsModal = true;
    },

    closeMultipleShiftsModal(duplicatedShifts) {
      this.visibleAddMultipleShiftsModal = false;

      if (duplicatedShifts) {
        this.fetchShifts();
      }
    },

    openAddShiftModal() {
      this.visibleAddShiftModal = true;
    },

    handleTagClick(record) {
      if (record.status == "not_signed") {
        this.openSignModal();
        this.signShiftId = record.id;
      }
    },

    openCloneAssociate() {
      this.visibleCloneModal = true;
      this.cloningAssociateNumber = this.editingAssociateNumber;
    },

    endCloneAssociate() {
      this.visibleCloneModal = false;
      this.cloningAssociateNumber = undefined;
    },

    startTour() {
      this.$tours["shiftsTour"].start();
    },
  },
  mounted() {
    this.fetchShifts();
    this.calculateTableHeight();
    window.addEventListener("resize", this.calculateTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateTableHeight);
  },
};
</script>

<style scoped>
.role-select {
  min-width: 150px;
}

.auth-tag {
  text-align: center;
}

.auth-tag:hover,
.suspected-tag:hover {
  cursor: pointer;
}

.already-existing-shift-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tags-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-associate-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.table-associate-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.orange-highlight {
  background-color: #ffb546;
}

.expanded-row {
  background-color: #f4f4f4;
}
</style>
