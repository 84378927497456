import router from "@/router";
import store from "@/store/index";
import axios from "axios";
import Cookies from "js-cookie";

const getRootAddress = () => {
  if (process.env.NODE_ENV === "production") {
    return "/";
  } else {
    return "http://localhost:8000/";
  }
};

const apiClient = axios.create({
  baseURL: getRootAddress(),
  // timeout: 30000,
  withCredentials: true,
});

apiClient.interceptors.request.use(
  (config) => {
    const timeZone = store.state.applicationState.timeZone;
    if (timeZone) {
      config.headers["Client-Timezone"] = timeZone;
    }

    const csrf = Cookies.get("csrftoken");
    if (csrf) {
      config.headers["X-CSRFToken"] = csrf;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      if (store.state.applicationState.token) {
        apiClient.post("/sign_out");
        store.commit("setToken", null);
      }

      router.push("/auth").catch(() => {});
    }

    return Promise.reject(error);
  }
);

export default apiClient;
