import apiClient from "@/services/api";

export default {
  async getAssociate({ commit, rootState }, { associate_number }) {
    try {
      const { data } = await apiClient.post("/api/associate/get", {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the associate."
          } Please try again later`
        );
      }

      commit("setAssociate", data.associate);
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociateById({}, { associate_id }) {
    try {
      const { data } = await apiClient.get(`/api/associate/${associate_id}`);

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the associate."
          } Please try again later`
        );
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getKPI({ commit, rootState }, { associate_number, start, end }) {
    try {
      const { data } = await apiClient.post("/api/associate/kpi/get", {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
        start,
        end,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the KPI."
          } Please try again later`
        );
      }

      commit("setAssociateKPI", data.kpi);
    } catch (error) {
      throw error.message;
    }
  },
  async patchAssociateActivate({}, { associate_id }) {
    try {
      const { data } = await apiClient.patch(
        `/api/associate/${associate_id}/activate`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
  async patchAssociateDeactivate({}, { associate_id }) {
    try {
      const { data } = await apiClient.patch(
        `/api/associate/${associate_id}/deactivate`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
  async postAssociateTerminate(
    { rootState },
    { associate_id, termination, user_id }
  ) {
    try {
      const { data } = await apiClient.post(`/api/associate/terminate`, {
        project_id: rootState.applicationState.currentProject.id,
        associate_id,
        user_id,
        termination: {
          ...termination,
          author: rootState.applicationState.associate.id,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
  async postAssociateCancelTerminate({}, { associate_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/associate/${associate_id}/termination/cancel`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
};
