<template>
  <a-modal
    :visible="visible"
    width="950px"
    :destroy-on-close="true"
    @cancel="closeModal"
  >
    <payment-info v-if="payment_id" :payment-id.sync="payment_id" />
    <create-associate
      :visible.sync="visibleCreateAssociateModal"
      @close="afterCreateAssociate"
    />

    <shift-confirmation
      v-if="associate.payment_info"
      :is-new="isNew"
      :associate_number="associate_number"
      :payment_type="associate.payment_info.type"
      :visible.sync="visibleConfirmationModal"
      @save="saveShift"
    />

    <shift-overlap-modal
      :visible.sync="visibleOverlapModal"
      :overlapping-shifts="overlappingShifts"
      @edit="editOverlappingShift"
    />

    <div
      v-if="loadingShift"
      class="loading-spin"
      style="height: 650px; background-color: white"
    >
      <a-spin />
    </div>
    <div v-else class="wrapper">
      <div class="column">
        <h3>{{ title }}</h3>

        <div v-if="isNew" class="associate-wrapper">
          <search-associates
            :disabled="multiply && !!initialValues.associateNumber"
            :value.sync="associate_number"
          />
          <a-button icon="plus" @click="openCreateAssociateModal"
            >Add New</a-button
          >
        </div>

        <a-button
          class="w-full"
          v-if="!isNew"
          icon="dollar"
          :disabled="!this.shiftForm.request_id"
          @click="openPaymentModal"
        >
          Payment
        </a-button>

        <shift-fields
          v-if="associate_number"
          ref="fieldsRef"
          :is-new="isNew"
          :associate_number="associate_number"
          :isLunchWaiver="isLunchWaiver"
        />
      </div>
      <div class="column">
        <shift-times />
        <shift-attachments :files="files" @save="saveAttachmentsIds" />
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button
          icon="save"
          type="primary"
          :disabled="!disableSaveButton"
          @click="handleAutofill"
          >Autofill</a-button
        >
        <a-button
          icon="save"
          type="primary"
          :disabled="!disableSaveButton"
          ghost
          :loading="loadingSave"
          @click="openConfirmationModal"
          >Save</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<script>
import PaymentInfo from "@/modules/payment/components/payment-info.vue";
import { createNamespacedHelpers } from "vuex";
import { Button, notification, Select, Spin } from "ant-design-vue";
import CreateNewAssociate from "@/components/create-associate.vue";
import shiftViewAttachmentsVue from "./shift-view-attachments.vue";
import shiftAttachmentsUploadVue from "./shift-attachments-upload.vue";
import shiftFormTimesVue from "./shift-form-times.vue";
import shiftFormFieldsVue from "./shift-form-fields.vue";
import { SHIFT_FORM_INITIAL_STATE } from "@/store/modules/shifts/constants";
import shiftConfirmAdjustVue from "./shift-confirm-adjust.vue";
import validation from "@/modules/shifts/mixins/ShiftValidationMixin";
import ShiftFormAttachments from "./shift-form-attachments.vue";
import ShiftOverlapModal from "./shift-overlap-modal.vue";
import SearchAssociates from "@/components/search-associates.vue";
import moment from "moment-timezone";

const { mapActions: shiftActions, mapState: shiftState } =
  createNamespacedHelpers("shifts");
const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");
const { mapActions: documentActions } = createNamespacedHelpers("document");

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-button": Button,
    "a-spin": Spin,
    "payment-info": PaymentInfo,
    "create-associate": CreateNewAssociate,
    "attachments-list": shiftViewAttachmentsVue,
    "shift-attachments-upload": shiftAttachmentsUploadVue,
    "shift-times": shiftFormTimesVue,
    "shift-fields": shiftFormFieldsVue,
    "shift-confirmation": shiftConfirmAdjustVue,
    "shift-attachments": ShiftFormAttachments,
    "shift-overlap-modal": ShiftOverlapModal,
    "search-associates": SearchAssociates,
  },
  mixins: [validation],
  props: {
    shiftId: {
      type: Number,
      default: undefined,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Function,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
    },
  },
  data() {
    return {
      loadingShift: false,
      loadingSave: false,

      shift_id: undefined,
      payment_id: undefined,
      associate_number: undefined,
      attachmentsIds: [],
      overlappingShifts: [],

      visibleCreateAssociateModal: false,
      visibleConfirmationModal: false,
      visibleOverlapModal: false,

      isLunchWaiver: false,
    };
  },
  watch: {
    associate_number: {
      handler(newVal) {
        if (newVal) this.fetchAssociate();
      },
    },
    visible: {
      handler(newVal) {
        if (newVal) {
          this.processInitialValues();
          this.setFormValues();
        }
      },
    },
    initialValues: {
      handler() {
        this.processInitialValues();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...shiftState({
      shift: (state) => state.shift,
      shiftForm: (state) => state.shiftForm,
    }),

    ...profileState({
      associate: (state) => state.associate,
    }),

    files() {
      return this.isNew ? [] : this.shift.shift.files;
    },

    isNew() {
      return !this.shiftId;
    },

    disableSaveButton() {
      return (
        this.shiftForm.work_end &&
        this.shiftForm.work_start &&
        this.associate_number
      );
    },

    title() {
      return this.isNew
        ? "Create Shift"
        : `${this.associate.first_name} ${this.associate.last_name}'s Shift №${this.shiftId}`;
    },
  },
  methods: {
    ...shiftActions([
      "getShiftWithAuth",
      "setShiftFields",
      "updateShift",
      "createShift",
      "postShiftConflict",
    ]),
    ...profileActions(["getAssociate"]),
    ...documentActions(["getDocumentByType"]),

    processInitialValues() {
      if (!this.initialValues) return;

      if (this.initialValues.associateNumber) {
        this.associate_number = this.initialValues.associateNumber;
      }

      if (this.initialValues.start) {
        this.shiftForm.work_start = this.resetSeconds(
          moment(this.initialValues.start)
            .utc(true)
            .tz(this.timeZone)
            .startOf("day")
            .add(510, "minutes")
        );

        this.shiftForm.work_end = this.workEndInitialTime;
      }
    },

    closeModal() {
      this.setShiftFields(SHIFT_FORM_INITIAL_STATE);
      this.associate_number = undefined;
      this.$emit("update:visible", false);
    },

    setFormValues() {
      if (!this.isNew && Object.keys(this.shift).length > 0) {
        this.associate_number = this.shift.associate.associate_number;
        const formattedFields = this.formatTimeFields(this.shift.shift);

        this.setShiftFields(formattedFields);
      }
    },

    handleAutofill() {
      this.$refs.fieldsRef.fillWorkTimes();
    },

    openCreateAssociateModal() {
      this.visibleCreateAssociateModal = true;
    },

    afterCreateAssociate(associate_number) {
      this.associate_number = associate_number;
    },

    openPaymentModal() {
      this.payment_id = this.shiftForm.request_id;
    },

    saveAttachmentsIds(ids) {
      this.attachmentsIds = ids;
    },

    openConfirmationModal() {
      if (this.multiply) {
        this.create(false);

        return;
      }
      this.visibleConfirmationModal = true;
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    editOverlappingShift(id) {
      this.visibleOverlapModal = false;
    },

    // async calls

    async fetchAssociate() {
      this.loadingShift = true;

      try {
        const payload = {
          associate_number: this.associate_number,
        };

        await this.getAssociate(payload);

        const data = await this.getDocumentByType({
          associate_id: this.associate.id,
          type: "lunch_waiver",
        });

        this.isLunchWaiver = data.signature !== null;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShift = false;
      }
    },

    async saveShift(autoSign) {
      this.isNew ? this.create(autoSign) : this.update(autoSign);
    },

    async create(autoSign) {
      this.loadingSave = true;

      try {
        const shift = {
          ...this.formatTimeFields(this.shiftForm),
          modified_by_admin: true,
          rest_break_checked: true,
          files: [...this.attachmentsIds],
        };

        const createPayload = {
          associate_number: this.associate_number,
          auto_sign: autoSign,
          shift,
        };

        if (this.multiply) {
          this.closeModal();
          this.$emit("edit", shift, this.associate_number);

          return;
        }

        const { error_code, overlapping_shift_ids } = await this.createShift(
          createPayload
        );

        if (error_code === "2") {
          const overlappingShifts = overlapping_shift_ids
            .map((shift) => `№${shift.id}`)
            .join(", ");

          throw `The shift overlaps with shifts: ${overlappingShifts}`;
        }

        this.showNotification("success", "Success", "Associate Shift Saved");

        this.closeModal();
        this.$emit("edit");
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingSave = false;
      }
    },

    async checkConflict() {
      try {
        const data = await this.postShiftConflict({
          associate_number: this.associate_number,
        });

        if (data.length > 0) {
          const overlappingShifts = data
            .flatMap((item) => item.shifts.map((shift) => `№${shift.id}`))
            .join(", ");

          throw `The shift overlaps with shifts: ${overlappingShifts}`;
        }
      } catch (error) {
        throw error;
      }
    },

    async update(autoSign) {
      this.loadingSave = true;

      try {
        const formattedFields = this.formatTimeFields(this.shiftForm);

        await this.checkConflict();

        const updatePayload = {
          associate_number: this.associate_number,
          shift: {
            ...formattedFields,
            modified_by_admin: true,
            rest_break_checked: this.formattedFields?.rest_break_checked
              ? this.formattedFields.rest_break_checked
              : true,
            files: [...this.attachmentsIds],
          },
          auto_sign: autoSign,
        };

        await this.updateShift(updatePayload);

        this.showNotification("success", "Success", "Associate Shift Saved");

        this.closeModal();
        this.$emit("edit");
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingSave = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.associate-wrapper,
.attachments-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 2fr 1fr;
}
</style>
