<template>
  <div>
    <div class="modal-header">
      <h3>{{ fullAssociateName ? 'Authorization List of ' + fullAssociateName : 'Authorizations List' }}</h3>
    </div>
    <a-table
      size="small"
      row-key="id"
      :columns="columns"
      :data-source="authsList"
      :pagination="false"
      :row-selection="rowSelection"
      :scroll="{ y: 250 }"
    >
      <span slot="type" slot-scope="text, record">
        {{ convertType(record.type) }}
      </span>
      <span slot="details" slot-scope="text, record">
        {{ record.details ?? "N/A" }}
      </span>
      <span slot="scheduleError" slot-scope="text, record">
        <a-icon v-if="record.schedule_error" type="check" />
        <a-icon v-else type="close" />
      </span>
      <span slot="history" slot-scope="text, record">
        <history-tags :history="record.history" :is-detail-auth="true" />
      </span>
      <span slot="provider" slot-scope="text, record">
        <template v-if="record.provider">
          {{ record.provider.first_name }} {{ record.provider.last_name }}
        </template>
        <template v-else> - </template>
      </span>
      <span slot="status" slot-scope="text, record">
        {{ getStatusText(record.status) }}
      </span>
      <span slot="reviewedBy" slot-scope="text, record">
        {{ getReviewedByName(record) }}
      </span>
      <span slot="reviewDate" slot-scope="text, record">
        {{ getReviewedDate(record) }}
      </span>
    </a-table>
  </div>
</template>

<script>
import { Button, Icon, Table, Tag } from "ant-design-vue";
import HistoryTags from "@/components/Auth/auth-history-tags.vue";
import shiftAuths from "@/helpers/shift-auths";
import Util from "@/util";

const AuthStatus = Object.freeze({
  INITIAL: "initial",
  ACCEPTED: "accepted",
  DECLINED: "declined",
});

export default {
  components: {
    "a-icon": Icon,
    "a-table": Table,
    "a-button": Button,
    "a-tag": Tag,
    "history-tags": HistoryTags,
  },
  props: {
    authsList: {
      type: Array,
      default: [],
    },
    change: {
      type: Function,
    },
    associateFirstName: {
      type: String,
      default: "",
    },
    associateLastName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedAuths: [],
    };
  },
  watch: {
    selectedAuths() {
      this.$emit("change", this.selectedAuths);
    },
  },
  computed: {
    fullAssociateName() {
      if (this.associateFirstName || this.associateLastName) {
        return `${this.associateFirstName} ${this.associateLastName}`.trim();
      }
      return null;
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedAuths,
        onChange: this.onSelectChange,
        // getCheckboxProps: record => ({
        //   props: {
        //     disabled: record.status != 'initial'
        //   }
        // })
      };
    },
    resolvedAuthsAvailable() {
      return this.authsList
        ? this.authsList.some((auth) => auth.status != AuthStatus.INITIAL)
        : false;
    },
    columns() {
      const columns = [
        {
          title: "Type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "History",
          key: "history",
          align: "center",
          scopedSlots: { customRender: "history" },
        },
        {
          title: "Provider",
          dataIndex: "provider",
          key: "provider",
          align: "center",
          scopedSlots: { customRender: "provider" },
        },
        {
          title: "Explanation",
          scopedSlots: { customRender: "details" },
          key: "details",
        },
        {
          title: "Schedule Error",
          key: "scheduleError",
          align: "center",
          scopedSlots: { customRender: "scheduleError" },
        },
        {
          title: "Status",
          key: "status",
          align: "center",
          scopedSlots: { customRender: "status" },
        },
      ];

      if (this.resolvedAuthsAvailable) {
        columns.push(
          {
            title: "Resolved By",
            key: "reviewedBy",
            align: "center",
            scopedSlots: { customRender: "reviewedBy" },
          },
          {
            title: "Resolve Date",
            key: "reviewDate",
            align: "center",
            scopedSlots: { customRender: "reviewDate" },
          }
        );
      }

      return columns;
    },
  },
  methods: {
    convertType: shiftAuths.convertType,

    onSelectChange(selectedAuths) {
      this.selectedAuths = selectedAuths;
    },

    getStatusText(statusCode) {
      switch (statusCode) {
        case AuthStatus.INITIAL:
          return "Initial";
        case AuthStatus.ACCEPTED:
          return "Accepted";
        case AuthStatus.DECLINED:
          return "Declined";
        default:
          return "Incorrect Status";
      }
    },
    getReviewedByName(record) {
      if (!record.reviewed_by) {
        return "-";
      }

      return Util.combineAssociateName(
        record.reviewed_by.first_name,
        record.reviewed_by.last_name
      );
    },
    getReviewedDate(record) {
      if (!record.review_date || !record.reviewed_by) {
        return "-";
      }

      return Util.convertDateTimeToCurrentTimeZoneFormattedString(
        record.review_date
      );
    },

    clearData() {
      this.selectedAuths = [];
    },
  },
  mounted() {
    if (this.authsList == null) {
      this.selectedAuths = [];
    }
  },
};
</script>

<style scoped>

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
</style>
