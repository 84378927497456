var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-column gap-2"},[_c('a-form-model',{ref:"formRef",attrs:{"layout":"horizontal","model":_vm.form,"rules":_vm.rules,"label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-form-model-item',{attrs:{"colon":false,"label-align":"left","label":"Associate","prop":"associate_id"}},[_c('search-associates',{attrs:{"is-associate-id":true,"value":_vm.form.associate_id},on:{"update:value":function($event){return _vm.$set(_vm.form, "associate_id", $event)}}})],1),_c('a-alert',{attrs:{"message":"Select the start and end dates for finding associate's shifts","type":"info","show-icon":""}}),_c('a-form-model-item',{attrs:{"colon":false,"label-align":"left","label":"Date Range","prop":"dates"}},[_c('a-range-picker',{attrs:{"separator":"-","format":"MM/DD/YYYY"},model:{value:(_vm.form.dates),callback:function ($$v) {_vm.$set(_vm.form, "dates", $$v)},expression:"form.dates"}})],1)],1),_c('a-table',{attrs:{"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
      getCheckboxProps: (record) => ({
        props: { disabled: record.shift.status === 'deleted' },
      }),
    },"rowKey":(record) => record.shift.id,"locale":{ emptyText: 'Shifts Not Found' },"loading":_vm.loadingShifts,"size":"small","pagination":_vm.pagination,"columns":_vm.columns,"data-source":_vm.associateShifts,"scroll":{ y: 200 }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"date",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(record.shift.scheduled_start))+" - "+_vm._s(_vm.formatDate(record.shift.scheduled_end))+" ")])}},{key:"status",fn:function(text, record){return _c('span',{},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.getShiftStatusTagText(record.shift.status))+" ")]),_c('a-tag',{attrs:{"color":_vm.getAuthStatusTagColor(record.shift.status)}},[_vm._v(" "+_vm._s(_vm.getShiftStatusTagLabel(record.shift.status))+" ")])],2)],1)}}])},[_c('template',{slot:"footer"},[(_vm.hasSelected)?_c('span',[_vm._v(_vm._s(`Selected ${_vm.selectedRowKeys.length} shifts`))]):_vm._e()])],2),_c('a-form-model-item',{attrs:{"labelCol":{ span: 0 },"colon":false,"label-align":"left","prop":"shift_ids"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }