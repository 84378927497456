<template>
  <a-modal
    title="Confirm Shift"
    :visible="visible"
    width="800px"
    @cancel="continueAdjustingShift"
    :destroy-on-close="true"
  >
    <div
      v-if="loadingAuth"
      class="loading-spin"
      style="height: 650px; background-color: white"
    >
      <a-spin />
    </div>
    <div class="authorizations-container">
      <div>
        Are you sure you want to
        {{ !isNew ? "adjust the" : "save this" }} shift?
      </div>
      <div
        v-if="authorizations && authorizations.length > 0"
        class="flex flex-column gap-2"
      >
        <span
          >Notice that the specified data will result in the creation of
          authorizations:</span
        >
        <a-table
          size="small"
          :data-source="authorizations"
          :columns="columns"
          :row-key="(record) => record.name"
          :pagination="false"
        >
          <span slot="authType" slot-scope="text, record">
            {{ getAuthorizationName(record.name) }}
          </span>
          <span slot="explanation" slot-scope="text, record">
            {{ getAuthorizationDescription(record.name) }}
          </span>
        </a-table>
      </div>
      <div class="flex flex-column gap-4">
        <a-radio-group v-model="signatureType">
          <a-radio value="admin">Sign with my signature</a-radio>
          <a-radio value="autosign">Sign with associate signature</a-radio>
          <a-radio value="associate">Send to associate for signature</a-radio>
        </a-radio-group>

        <signature
          v-if="signatureType === 'admin'"
          checkbox-label="I agree that all data is true and correct"
          :associate="signatureAssociate"
          :available-signature="adminAssociateSignature"
          @checked="setSignatureCheckboxValue"
          @save-signature="setSignatureId"
          ref="signatureRef"
        />
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button @click="continueAdjustingShift"> Cancel </a-button>
        <a-button
          type="primary"
          :loading="loadingSave"
          :disabled="signatureType === 'admin' && !signatureCheckboxChecked"
          @click="openConfirmModal"
        >
          Sign & Submit
        </a-button>
      </div>
    </template></a-modal
  >
</template>

<script>
import Util from "@/util";
import {
  Button,
  Table,
  Switch,
  Checkbox,
  Spin,
  Radio,
  notification,
  Modal,
} from "ant-design-vue";
import Signature from "@/components/signature.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: shiftActions } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-button": Button,
    "a-table": Table,
    "a-switch": Switch,
    "a-checkbox": Checkbox,
    "a-spin": Spin,
    "a-radio-group": Radio.Group,
    "a-radio": Radio,
    signature: Signature,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    associate_number: {
      type: String,
      default: undefined,
    },
    save: {
      type: Function,
    },
    payment_type: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      columns: [
        {
          title: "Authorization Type",
          key: "authType",
          scopedSlots: { customRender: "authType" },
        },
        {
          title: "Explanation",
          key: "explanation",
          scopedSlots: { customRender: "explanation" },
        },
      ],
      autoSign: false,
      signatureCheckboxChecked: false,
      signatureId: undefined,
      signatureType: "admin",

      loadingSave: false,
      loadingAuth: false,

      authorizations: [],
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) this.checkAuthorizations();
    },
    signatureType: {
      handler(newVal) {
        this.signatureId = undefined;

        if (newVal === "autosign") {
          this.autoSign = true;
        } else {
          this.autoSign = false;
        }
      },
    },
  },
  computed: {
    lunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.lunch_duration
      );
    },
    secondLunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(
        this.$store.state.applicationState.timeConstants.second_lunch_duration
      );
    },

    authorizationDescriptionEnum() {
      return {
        no_lunch_authorization: `Specify the lunch time. Lunch should be on this shift.`,
        no_second_lunch_authorization: `Specify the second lunch time. Second lunch should be on this shift.`,
        late_end_authorization: `Adjust the work end. It should coincide with the scheduled work end.`,
        early_start_authorization: `Adjust the work start. It should coincide with the scheduled work start.`,
        early_lunch_end_authorization: `Increase the duration of lunch. Lunch duration should be
          at least ${this.lunchDuration} minutes.`,
        early_second_lunch_end_authorization: `Increase the duration of second lunch.
          Second lunch duration should be at least ${this.secondLunchDuration} minutes.`,
        no_actual_time_authorization: "Shift added without tracked worked time",
        overtime_authorization: `Authorize overtime. The work hours should not exceed the scheduled hours of work.`,
        edit_shift_authorization: " - ",
        shift_info: " - ",
        outside_work_area_authorization: " - ",
        employee_issue: " - ",
        absence_on_shift_authorization: `Authorize absence on shift. Associate was absent for a valid reason.`,
        late_start_authorization: `Adjust the work start. It should coincide with the scheduled work start.`,
        early_end_authorization: `Adjust the work end. It should coincide with the scheduled work end.`,
        first_lunch_period_authorization: " - ",
        signed_actual_time_discrepancy: `There is a time discrepancy between signed and actual time.`,
        no_rest_break_authorization: " - ",
        associate_not_recognized_authorization: " - ",
        uncompleted_required_tasks_authorization:
          "Not completing critical tasks",
      };
    },
    signatureAssociate() {
      return {
        name: Util.combineAssociateName(
          this.$store.state.applicationState.associate.first_name,
          this.$store.state.applicationState.associate.last_name
        ),
        email: this.$store.state.applicationState.associate.email,
        phone: this.$store.state.applicationState.associate.phone,
      };
    },

    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    },
  },
  methods: {
    ...shiftActions(["postAuthorizationCheck", "updateShiftField"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    getAuthorizationName(authorizationKey) {
      return (
        this.$store.state.applicationState.extendedShiftAuthTypes.find(
          (item) => item.key === authorizationKey
        ).name ?? ""
      );
    },
    getAuthorizationDescription(authorizationKey) {
      return (
        this.authorizationDescriptionEnum[authorizationKey] ??
        "Unfortunately, explanation is not available"
      );
    },

    continueAdjustingShift() {
      this.$emit("update:visible", false);
    },

    setSignatureCheckboxValue(e) {
      this.signatureCheckboxChecked = e;
    },

    openConfirmModal() {
      if (this.payment_type === "Employee" && this.signatureType === "admin")
        Modal.confirm({
          title: "Are you sure?",
          content: `Are you sure you want to sign this shift on behalf of the EMPLOYEE? This could lead to legal issues down the line. It’s best to only do this in rare, exceptional cases.`,

          onOk: () => {
            this.saveShift();
          },
          onCancel() {},
        });
      else {
        this.saveShift();
      }
    },

    setSignatureId(signatureId) {
      this.signatureId = signatureId;
      this.updateShiftDetails();
    },

    async saveShift() {
      if (this.signatureType === "admin") {
        await this.$refs.signatureRef.saveSignature();
      } else {
        this.updateShiftDetails();
      }
    },

    updateShiftDetails() {
      this.updateShiftField({
        field: "signature",
        value: this.signatureId ? { id: this.signatureId } : undefined,
      });

      this.updateShiftField({
        field: "authorizations",
        value: this.authorizations.map((item) => item.name),
      });

      this.$emit("save", this.autoSign);
      this.continueAdjustingShift();
      this.clearData();
    },

    async checkAuthorizations() {
      try {
        this.loadingAuth = true;

        const data = await this.postAuthorizationCheck({
          associate_number: this.associate_number,
        });

        this.authorizations = data.map((item) => ({ name: item }));
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAuth = false;
      }
    },

    clearData() {
      this.autoSign = false;
      this.authorizations = [];
      this.signatureId = undefined;
      this.signatureType = "admin";
    },
  },
};
</script>

<style scoped>
.authorizations-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.authorization-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
