<template>
  <div class="signature-container">
    <a-modal
      title="Confirm"
      v-model="isConfirmVisible"
      @cancel="closeConfirmModal"
    >
      <template slot="footer">
        <a-button key="back" @click="closeConfirmModal">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="disabledConfirm"
          @click="submitConfirmModal"
          >Submit</a-button
        >
      </template>
      <div class="flex flex-column gap-2">
        <div class="">
          <span
            >To use your default digital signature, please enter your full name:
            <b> {{ upperCaseName }}</b>
          </span>
        </div>

        <div>
          <b>Name <span class="required-field">*</span></b>
          <a-input v-model="name" placeholder="Enter name" />
        </div>
      </div>
    </a-modal>

    <div v-if="checkboxLabel" class="signature-checkbox">
      <a-checkbox
        v-if="!signatureDate"
        v-model="isChecked"
        class="signature-checkbox"
        @change="emitChecked"
      >
        {{ checkboxLabel }}
      </a-checkbox>
      <span v-else>{{ checkboxLabel }}</span>
    </div>
    <div class="signature-content">
      <div class="flex justify-content-between">
        <span class="associate-name">{{ associate.name }}</span>
        <a-button-group
          v-if="checkboxLabel || !signature"
          size="small"
          class="right-upper-corner"
        >
          <a-tooltip>
            <template slot="title"> Clear Signature </template>
            <a-button icon="redo" @click="clearSignature" />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Undo Signature </template>
            <a-button icon="left" @click="undoSignature" />
          </a-tooltip>
        </a-button-group>

        <div v-else class="right-upper-corner">
          <div><b>E-Signed:</b></div>
          <div>
            <b>{{ visualSignatureDateAndTime }}</b>
          </div>
        </div>
      </div>

      <VueSignaturePad
        class="signature-pad"
        width="100%"
        height="90px"
        ref="signaturePad"
      />
      <div class="signature-footer">
        <span class="associate-email">{{ associate.email }}</span>
        <span class="associate-phone">{{ associate.phone }}</span>
      </div>
    </div>

    <!-- <div
      v-show="availableSignature && checkboxLabel"
      class="signature-checkbox"
    >
      <a-checkbox
        @change="openNameConfirm"
        :checked="isDefaultSignature"
        class="signature-checkbox"
      >
        Use default signature
      </a-checkbox>
    </div> -->
  </div>
</template>

<script>
import moment from "moment-timezone";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Tooltip,
  notification,
} from "ant-design-vue";
import api from "@/api";

export default {
  components: {
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-checkbox": Checkbox,
    "a-tooltip": Tooltip,
    "a-modal": Modal,
    "a-input": Input,
  },
  mixins: [api],
  emits: ["save-signature", "checked"],
  data() {
    return {
      isChecked: false,
      isDefaultSignature: false,
      isConfirmVisible: false,

      signature: this.availableSignature ?? null,
      name: undefined,
    };
  },
  props: {
    associate: {
      type: Object,
    },
    checkboxLabel: {
      type: String,
    },
    availableSignature: {
      type: Object,
    },
    signatureDate: {
      type: String,
      default: undefined,
    },
  },
  watch: {
    availableSignature: {
      deep: true,
      handler() {
        this.availableSignatureHandler();
      },
    },
    isDefaultSignature: {
      handler(newVal) {
        this.availableSignatureHandler();

        if (newVal && this.availableSignature) {
          this.$emit("save-signature", undefined);
        }
      },
    },
    signature: {
      handler() {
        this.emitChecked();
      },
    },
  },
  computed: {
    upperCaseName() {
      return this.associate.name.toUpperCase();
    },
    disabledConfirm() {
      return this.name !== this.upperCaseName;
    },
    dateTimeFormat() {
      return this.$store.state.applicationState.dateTimeFormat;
    },
    visualSignatureDateAndTime() {
      if (!this.signature) {
        return "";
      }

      return moment(this.signature.signed_date).format(this.dateTimeFormat);
    },
  },
  methods: {
    onEnd() {
      console.log("=== End ===");
    },
    handleResize() {
      if (this.signature) {
        this.$nextTick(() => {
          this.$refs.signaturePad.fromDataURL(this.signature.image_url);
        });
      }
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();

      this.signature = null;
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        notification["warning"]({
          message: "Signature Upload Error",
          description:
            "Signature that you provided is empty. Please draw signature again",
        });

        return;
      }

      if (this.checkboxLabel) {
        this.uploadSignature(data);
      } else {
        this.$emit("save-signature", data);
      }
    },
    emitChecked() {
      this.$emit("checked", this.isChecked);
    },
    availableSignatureHandler() {
      if (
        this.availableSignature &&
        this.availableSignature.image_url &&
        (this.isDefaultSignature || !this.checkboxLabel)
      ) {
        this.$refs.signaturePad.clearSignature();
        this.$refs.signaturePad.fromDataURL(this.availableSignature.image_url);
        this.$refs.signaturePad.lockSignaturePad();
        this.signature = this.availableSignature;
      } else {
        this.clearSignature();
      }
    },

    openNameConfirm(event) {
      const checked = event.target.checked;

      if (checked) {
        this.isConfirmVisible = true;
      } else {
        this.isDefaultSignature = checked;
      }
    },
    closeConfirmModal() {
      this.isConfirmVisible = false;
      this.name = undefined;
    },
    submitConfirmModal() {
      this.isDefaultSignature = true;
      this.closeConfirmModal();
    },

    async uploadSignature(signature) {
      try {
        const file = await this.dataUrlToFile(signature, "sign");

        const { data } = await this.apiUploadFiles([file]);

        if (data.error_code && data.error_code !== "0") {
          notification["warning"]({
            message: "Upload Error",
            description: "Please try again to upload sign",
          });
          return;
        }

        if (data.ids.length) {
          const { data: signatureData } = await this.apiSignatureContextSave(
            data.ids[0]
          );

          if (signatureData.error_code && signatureData.error_code !== "0") {
            notification["warning"]({
              message: "Sign Shift Error",
              description: "Please try again to sign shift",
            });
            return;
          }

          this.$emit("save-signature", signatureData.signature.id);
          this.signature = signatureData.signature;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async dataUrlToFile(dataUrl, fileName) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], fileName, { type: "image/png" });
    },
  },
  mounted() {
    this.availableSignatureHandler();
    this.$emit("checked", false);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.signature-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.signature-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.signature-content {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 16px;
  background: #fff;
}

.signature-content span {
  user-select: none;
}

.signature-pad {
  margin: 16px 0;
  display: flex;
  justify-content: center;
  min-width: 280px;
}

.associate-name {
  font-weight: bold;
  font-size: 14px;
}

.right-upper-corner {
  text-align: right;
  font-size: 14px;
}

.signature-footer {
  display: flex;
  gap: 10px;
}

.associate-email,
.associate-phone {
  font-size: 12px;
}
</style>
